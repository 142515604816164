@font-face {
  font-family: "JetBrains";
  src: url(/assets/font/JetBrainsMono-Regular.woff2);
  font-weight: 400;
  font-variant-ligatures: normal;
}

@font-face {
  font-family: "JetBrains";
  src: url(/assets/font/JetBrainsMono-Thin.woff2);
  font-weight: 200;
  font-variant-ligatures: normal;
}

@font-face {
  font-family: "JetBrains";
  src: url(/assets/font/JetBrainsMono-Italic.woff2);
  font-weight: normal;
  font-variant-ligatures: normal;
  font-style: italic;
}

@font-face {
  font-family: "JetBrains";
  src: url(/assets/font/JetBrainsMono-ThinItalic.woff2);
  font-weight: 200;
  font-variant-ligatures: normal;
  font-style: italic;
}

@font-face {
  font-family: "JetBrains";
  src: url(/assets/font/JetBrainsMono-Bold.woff2);
  font-weight: bold;
  font-variant-ligatures: normal;
}

html, body {
  line-height: 1.5em;
  background: #121212;
  padding: 1em;
  color: #fff;
  font-size: 12pt;
  font-family: "JetBrains";
  font-weight: 400;
  font-style: normal;
  margin: 1em 4em;
}

#content, #topLink {
  clear: both;
}

#topLink {
  padding-top: 8em;
}

h1.nameTitle {
  font-size: 1.2em;
}

#homeLink a, #topLink a {
  font-weight: 200;
  text-decoration: none
}

#homeLink a span, #topLink a span {
  text-decoration: underline;
}

a.current, a.current:visited, a:hover, a:visited:hover {
  color: orange;
}

a:visited {
  color: #ccc;
}

a {
  color: #fff;
}


a[href^="http"]:after {
  content: "->";
  vertical-align: super;
  font-size: 0.5em;
}

h1 {
  font-weight: bold;
  font-size: 1.5em;
}

#content {
  display: flex;
  width: 100%;
}

#home h2 {
  font-size: 1em;
  font-weight: normal;
  font-style: italic;
}

#home ul {
  padding: 0
}

#home li {
  list-style-type: none;
  padding: 0;
  margin: 0 1em;
}

#home li.cat {
  margin-top: 1em;
}

.ext_link {
  font-size: 0.7em;
}

main {
  order: 2;
  flex-basis: 50%;
  max-width: 750px;
}

main img {
  max-width: 100%;
  margin: 1em 0;
}

hr {
  color: #aaa;
  border: none;
  border-top: 2px #666 solid;
}

figure {
  margin-left: 0;
}

figure img {
  margin-bottom: 0;
}

figcaption {
  font-size: 0.8em;
}

.project {
	margin-top: 3em;
}

.metadata {
  padding: 1ch;
  background: #222;
  margin-bottom: 2em;
}

.metadata div:not(:first-of-type) {
  display: block;
  margin-top: 1em;
}

.metadata .label {
  font-style: italic;
  font-weight: 200;
}

@media only screen and (max-width: 768px) {
	html, body {
		margin: 0;
	}
  #content {
    flex-direction: column-reverse;
  }
  main {
    width: 100%;
    margin-top: 0;
  }
  nav {
    width: 100%;
    border-top: solid 1px #aaa;
    margin-top: 2em;
    padding-top: 2em;
  }
  nav li {
    margin-top: 1em;
  }
  h1.nameTitle {
    font-size: 0.9em;
    margin-bottom: 2em;
  }
}
